// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

// local imports
import { styles } from './Commissions.style';

function Commissions(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const blueBreak = useMediaQuery('(min-width:950px)');
    const mobileDots = useMediaQuery('(min-width:900px)');
    const smallerDots = useMediaQuery('(min-width:510px)');
    const reallySmall = useMediaQuery('(min-width:340px)');

    return (
        <Box className={classes.root}>
            <Box className={classes.topContainer}>
                <Box className={classes.titleContainer}>
                    <p id="top">La única</p>
                    <p className="title">CUENTA</p>
                    <p id="snd" className="title">
                        QUE
                    </p>
                    <p id="td" className="title">
                        NECESITAS
                    </p>
                </Box>
                <p className={classes.topText}>
                    Creamos un servicio financiero que nosotros usamos pensando en ti. Desde tu teléfono móvil tienes
                    acceso a tu dinero de forma segura e inmediata 24/7, puedes enviar y recibir fondos con múltiples
                    opciones.
                </p>
            </Box>

            <Box className={classes.midContainer}>
                <br></br>
                <p id="subtitle">Cuenta de Fondos de Pago Electrónico</p>
                <p className={classes.subtext} style={{ marginBottom: '0px' }}>
                    Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico
                </p>
                <p className={classes.subtext}>RECA: 15717-458-035582/03-01762-0724</p>
                <p className="header">TRANSFERENCIAS</p>
                <Box className={classes.comisionContainer}>
                    <Box className={`${classes.comision} blueComision`}>
                        <p className="comisionText">
                            Cuenca a otros bancos ..
                            {reallySmall ? '....' : ''}
                            {smallerDots ? '..........' : ''}
                            {mobileDots ? '.................................' : ''}
                        </p>
                        <Box className={classes.blueContainer}>
                            <Box
                                className={classes.rightNumber}
                                paddingLeft={0.5}
                                style={{
                                    flexDirection: !blueBreak ? 'column' : 'row',
                                    alignItems: !blueBreak ? 'flex-start' : 'center',
                                }}
                            >
                                <p className="blueText">15</p>
                                <Box paddingLeft={blueBreak ? 0 : 1}>
                                    <p className="endText">transferencias</p>
                                    <p className="endText">mensuales gratis</p>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={`${classes.comision} blueComision`} textAlign={'left'}>
                        <p className="comisionText">
                            A partir de transferencia {!mobileDots && <br />}número <strong>16</strong> ..
                            {reallySmall ? '...' : ''}
                            {smallerDots ? '.........' : ''}
                            {mobileDots ? '..............' : '......................'}
                        </p>
                        <Box className={classes.blueContainer} paddingLeft={0.1}>
                            <Box
                                className={classes.rightNumber}
                                style={{
                                    flexDirection: !blueBreak ? 'column' : 'row',
                                    alignItems: !blueBreak ? 'flex-start' : 'center',
                                }}
                            >
                                <Box paddingLeft={blueBreak ? 0 : 1}>
                                    <p className="endText">
                                        Te informamos que en tu cuenta Cuenca puedes realizar cada mes hasta 15
                                        transferencias electrónicas de fondos gratuitas a una entidad financiera
                                        distinta a Cuenca. Una vez superado este límite mensual, cada una de las
                                        transferencias realizadas hacia entidades distintas a Cuenca tendrán una
                                        comisión de $8.62 + IVA = ($10.00).
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <p className="header">OTROS</p>
                <Box id="bottom" className={classes.comisionContainer}>
                    <Box className={classes.comision} style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Box marginTop={2}>
                            <p className="comisionText">
                                Manejo de cuenta ...........
                                {reallySmall ? '......' : ''}
                                {smallerDots ? '.........' : ''}
                                {mobileDots ? '................................' : ''}
                            </p>
                        </Box>
                        <Box className={classes.rightNumber} marginLeft={0.3}>
                            <p style={{ marginBottom: '2px' }} className="endText">
                                <span className="blueText" style={{ whiteSpace: 'nowrap' }}>
                                    $ 50
                                </span>{' '}
                                <Box marginLeft={1.4}>($43.10 + IVA) mensuales por concepto de manejo de cuenta.</Box>
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Commissions);
